import { colors } from '@mui/material';
import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import config from '@plone/volto/registry';

export const LinkSchema = ({ intl }) => {
    const schema = {
        title: 'Button',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['name', 'link', 'isDownload', 'target'],
            },
        ],

        properties: {
            name: {
                title: 'Name',
                description: 'Anzeige Name',
                type: 'string',
            },
            link: {
                title: 'Link',
                widget: 'object_browser',
                mode: 'link',
                required: true,
                allowExternals: true,
            },
            isDownload: {
                title: 'Download',
                description: 'Ist ein Objekt zum downloaden',
                type: 'boolean',
            },
            target: {
                title: 'Neuer Tab',
                description: 'Link in neuem Fenster öffnen',
                type: 'boolean',
            }
        },
        required: [],
    };

    addStyling({ schema, intl });

    schema.properties.styles.schema.properties.isButton = {
        title: 'Button',
                description: 'Als Button anzeigen',
                type: 'boolean',
    }

    schema.properties.styles.schema.properties.textSize = {
        title: 'Text Grösse',
        type: 'string',
        factory: 'Choice',
        choices: [
            ['xx-small', 'xx-small'],
            ['x-small', 'x-small'],
            ['small', 'small'],
            ['medium', 'medium'],
            ['large', 'large'],
            ['x-large', 'x-large'],
            ['xx-large', 'xx-large'],
            ['xxx-large', 'xxx-large'],
          ],
    }

    schema.properties.styles.schema.properties.align = {
        widget: 'align',
        title: 'Anordnen',
        actions: ['left', 'right', 'center'],
        default: 'left',
    }

    schema.properties.styles.schema.properties.backgroundColor = {
        title: 'Hintergrund-farbe',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.available_colors,
    }

    schema.properties.styles.schema.properties.textColor = {
        title: 'Textfarbe',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.available_colors,
    }

    schema.properties.styles.schema.properties.hoverColor = {
        title: 'Hover Farbe',
        description: 'Hover Farbe für den Text.',
        type: 'color',
        widget: 'style_simple_color',
        available_colors: config.settings.available_colors,
    }

    schema.properties.styles.schema.fieldsets[0].fields = [
        'isButton',
        'textSize',
        'align',
        'backgroundColor',
        'textColor',
        'hoverColor'
    ];

    return schema;
};

export default LinkSchema;