import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Segment role="contentinfo" padded id="footer">
            <div className="footerTop">

                <div className='bannerLeftDiv'>
                    <a href='https://www.lauperswil.ch/' target='_blank'>
                        <img src='../lauperswil.png' alt='Wappen Lauperswil' className='bannerLeft bannerImage' />
                    </a>
                </div>

                <div className='footerListWrapper'>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <p className="item">
                                <i className="ri-copyright-line"></i>
                                {currentYear}&nbsp;
                                <FormattedMessage
                                    id='Footer-Copyright'
                                    defaultMessage="Gemeindeverband Wasserversorgung Zollbrück"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item icon">
                            <i className="ri-circle-fill"></i>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Plz-Ort'
                                    defaultMessage="3436 Zollbrück"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item icon">
                            <i className="ri-circle-fill"></i>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <a href="/">
                                    <FormattedMessage
                                        id="Footer-Web"
                                        defaultMessage="zwv.ch"
                                    />
                                </a>
                            </p>
                        </div>
                    </List>
                </div>

                <div className='bannerRightDiv'>
                    <a href='https://www.ruederswil.ch/' target='_blank'>
                        <img src='../ruederswil.png' alt='Wappen Rüderswil' className='bannerRight bannerImage' />
                    </a>
                </div>
            </div>
            <div className='footerBottom'>
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <a className="item" href="./impressum">
                                <FormattedMessage
                                    id='Footer-Imprint'
                                    defaultMessage="Impressum"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <a className="item" href="./datenschutz">
                                <FormattedMessage
                                    id='Footer-Privacy'
                                    defaultMessage="Datenschutzerklärung"
                                />
                            </a>
                        </div>
                    </List>
                </div>
            </div>
        </Segment>
    );
};

export default injectIntl(Footer);