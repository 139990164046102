import React from 'react';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import LinkData from "./LinkData";
import LinkView from './LinkView';

const LinkEdit = (props) => {
  const { data, block, onChangeBlock, selected } = props;
  return (
    <>
      <LinkView {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <LinkData
          {...props}
          data={data}
          block={block}
          onChangeBlock={onChangeBlock}
        />
      </SidebarPortal>
    </>
  );
};

export default LinkEdit;
